<template>
  <section id="about">
    <v-container fluid style="background: white;">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="7" xl="4">
              <h1 class="font-weight-light text-md-h4 text-h5">Driven to Serve</h1>
              <!-- <h1 class="font-weight-light display-1 mb-3">
                Lorem ipsum dolor!
              </h1> -->
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <svgIcons max-width="150px" class="d-none d-sm-block mr-4" :id="4"/>
                  <!-- <v-img src="@/assets/img/icons/mission.png" /> -->
                  <p class=" font-weight-regular text-lg-h6">
                    We are here to improve the value of students’ education by encouraging and enabling them to create and understand the plan that's best for them.
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <svgIcons max-width="150px" height="100px" class="d-none d-sm-block mr-4" :id="5"/>
                  <!-- <v-img src="@/assets/img/icons/happiness.png" max-width="70px" class="mr-4" /> -->
                  <p class=" font-weight-regular text-lg-h6">
                    Through this we envision an individualized higher education system <strong>that can suit each student's
                    unique needs, goals, and passions</strong>.
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <svgIcons class="d-none d-sm-block mr-4" max-width="70px"  :id="6"/>
                  <!-- <v-img src="@/assets/img/icons/icon3.svg" max-width="70px" class="mr-4" /> -->
                  <p class=" font-weight-regular text-lg-h6">
                    To do this, we strive to:
                    <ul class="text-justify font-weight-regular text-lg-h6">
                      <li>Make students <em>want</em> to plan</li>
                      <li>Enable <em>every</em> student to create their own plan</li>
                      <li><em>Connect</em> students with those that can help</li>
                      <li>Remain dedicated to the needs of the student</li>
                    </ul>
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" xl="4" class="d-none d-md-flex">
                  <svgIcons max-width="300px" class="d-block ml-auto mr-auto" :id="7"/>

              <!-- <v-img src="@/assets/img/student_book.png" class="d-block ml-auto mr-auto" max-width="300px" /> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>

import SvgIcons from "./SvgIcons.vue";
export default {
  components: {
    SvgIcons,
  }
}
</script

<style scoped>
  #about {
    background-color: #f4f7f5;
  }
</style>
