var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"app":"","temporary":"","dark":"","src":require("@/assets/img/bgDrawer.jpg")},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{attrs:{"src":require("@/assets/img/icon_white_2.png"),"alt":"Logo"}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v("Envisage Planner")]),_c('v-list-item-subtitle',[_vm._v("Menu")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.items),function(ref,i){
var icon = ref[0];
var text = ref[1];
var link = ref[2];
return _c('router-link',{key:i,staticStyle:{"text-decoration":"none"},attrs:{"link":"","to":link}},[_c('v-list-item',[_c('v-list-item-icon',{staticClass:"justify-center"},[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitile-1"},[_vm._v(_vm._s(text))])],1)],1)],1)}),1)],1),_c('v-app-bar',{staticClass:"px-15",class:{ expand: _vm.flat },style:(("margin-top:" + _vm.siteNotifHeight + "px")),attrs:{"app":"","color":_vm.color,"flat":_vm.flat,"dark":"","id":"site-nav-bar"}},[_c('v-toolbar-title',[_c('v-img',{attrs:{"src":require("@/assets/img/logo_white.svg"),"contain":"","max-width":"200px","max-height":"50px"}})],1),(!_vm.isMobile)?_c('v-spacer'):_vm._e(),(_vm.isMobile)?_c('v-app-bar-nav-icon',{staticClass:"mr-4",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_c('div',[_vm._l((_vm.items),function(ref,i){
var icon = ref[0];
var text = ref[1];
var link = ref[2];
return _c('router-link',{key:i,staticStyle:{"text-decoration":"none"},attrs:{"to":link}},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){return _vm.$vuetify.goTo('#hero')}}},[_c('span',[_vm._v(_vm._s(text))])])],1)}),_c('v-btn',{staticClass:"ml-4",attrs:{"light":"","href":"https://app.envisageplanner.com","rounded":"","id":"open-app-nav"}},[_c('span',[_vm._v("Open Web App")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }