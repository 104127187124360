import Vue from "vue";
import Router from "vue-router";
import Home from "./pages/Home.vue";
import Investor from "./pages/Investor.vue";
import Join from "./pages/Join.vue";
import Work from "./pages/Work.vue";
import Team from "./pages/Team.vue";

import ErrorPage from "./pages/ErrorPage.vue";



Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { name: "landing-page", path: "/", component: Home },
    { name: "investor", path: "/investor", component: Investor },
    { name: "join", path: "/join", component: Join},
    { name: "work", path: "/work", component: Work },
    { name: "team", path: "/team", component: Team },
    { name: "404", path: '*', component: ErrorPage}
  ]
});
