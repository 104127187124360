<template>
  <section class="pb-8 pt-8" id="team">
    <v-row align="center" justify="center">
      <h1 class="ma-5 text-h3">Meet the Team</h1>
    </v-row>
    <v-container fluid v-for="(person, i) in teamMembers" :key="i">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" lg="5" xl="4" align="center" order="1" :order-md="i % 2 ? '2' : '1'">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card pa-0 mb-0"
                  max-width="200"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover, 'zoom-efect': hover }"
                >
                  <v-img
                    v-if="person.img === 'tim_hi.jpg'"
                    src="@/assets/img/team/tim_hi.jpg"
                    placeholder="@/assets/img/team/tim_lo.jpg"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover, 'mb-0': true }"
                  />
                  <v-img
                    v-if="person.img === 'david.jpg'"
                    src="@/assets/img/team/david.jpg"
                    placeholder="@/assets/img/team/david.jpg"
                    class="mb-0 ml-auto mr-auto"
                    height="300"
                    :class="{ 'zoom-efect': hover, 'mb-0': true }"
                  />
                  <v-img
                    v-if="person.img === 'connor.jpg'"
                    src="@/assets/img/team/connor.jpg"
                    placeholder="@/assets/img/team/connor.jpg"
                    class="mb-0 ml-auto mr-auto"
                    height="300"
                    :class="{ 'zoom-efect': hover, 'mb-0': true }"
                  />
                  <v-img
                    v-if="person.img === 'jamie_med.jpg'"
                    src="@/assets/img/team/jamie_med.jpg"
                    placeholder="@/assets/img/team/jamie_lo.jpg"
                    class="mb-0 ml-auto mr-auto"
                    height="300"
                    :class="{ 'zoom-efect': hover, 'mb-0': true }"
                  />
                  <v-img
                    v-if="person.img === 'hibschman_med.jpg'"
                    src="@/assets/img/team/hibschman_med.jpg"
                    placeholder="@/assets/img/team/hibschman_lo.jpg"
                    class="mb-0 ml-auto mr-auto"
                    height="300"
                    :class="{ 'zoom-efect': hover, 'mb-0': true }"
                  />
                </v-card>
              </v-hover>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="5"
              xl="4"
              align="center"
              justify="center"
              order="2"
              :order-md="i % 2 ? '1' : '2'"
            >
              <v-card elevation="2">
                <v-card-text>
                  <h1 class="font-weight-light display-1 text-center">{{ person.name }}</h1>
                  <h3 class="text-h6 text-center ">{{ person.position }}</h3>
                  <p class="text-left mt-3" v-for="(paragraph, i) in person.body" :key="i">{{ paragraph }}</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="ma-5 pa-5 pb-0" />
        </v-col>
      </v-row>
    </v-container>
    <!-- <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg" />
    </div> -->
  </section>
</template>

<style scoped>
  #contact {
    background-color: #f4f7f5;
  }

  .svg-border-waves .v-image {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
    overflow: hidden;
  }
  .zoom-efect {
    transform: scale(1.05);
  }
  .up {
    transform: translateY(-20px);
    transition: 0.5s ease-out;
  }
</style>

<script>
  // import {db} from '@/main'

  export default {
    data: () => ({
      teamMembers: [
        {
          name: 'Tim Swanson',
          position: 'Founder, CEO, Lead Software Engineer',
          body: [
            `I am a Senior Computer Science student at Taylor University. I live in Gerig Hall (FOSO's PA last year!), and love putting my many interests to work in Envisage, whether that's coding, interacting with people, marketing, or directing Envisage in a way that seeks to serve others like Christ did.`,
            `Ever since I started working on a tool that would demonstrate how academic planning could be dramatically improved at my school, I realized a responsibility to make this happen. Since then, I have been driven by the conviction of Envisage's power to improve the education for many future students.`,
          ],
          img: 'tim_hi.jpg',
        },
        {
          name: 'David Chalifoux',
          position: 'Software & Infrastructure Engineer',
          body: [
            `Hey, I'm David! I am currently a senior at Taylor University and major in Computer Science with Systems. I love working with web technologies, and I first got into web development when I was 10 years old. I've worked on various projects and startups since then, but I have always had an interest in creating software which improves the educational experience for students. My work involves developing both our frontend and backend stacks while also managing our cloud infrastructure.`,
          ],
          img: 'david.jpg',
        },
        {
          name: 'Connor White',
          position: 'Backend and API Developer',
          body: [
            'I am Connor White, a Senior Computer Science/Systems major at Taylor University. I have lived on 2C Wengatz for most of my time at Taylor, and now commute from my home in Upland! I’ve spent most of my time at Taylor coding, running in the cross country team (coached by my Dad, Dr. Quinn White) with my teammates, and doing shenanigans with my wing or teammates!',
            'I was pumped about Envisage as soon as Tim explained to me his dream and mission statement for the company. I love the idea of students no longer being intimidated by the idea of planning their college experience, and missing out on key classes or just not being able to graduate on time due to planning confusion. I am driven to help Tim complete his goal of making college planning easy!',
          ],
          img: 'connor.jpg',
        },
        {
          name: 'Jamie Haenggi',
          position: 'Business Advisor',
          body: [
            `Jamie is the Executive Vice President and Chief Customer Officer at  ADT Security Services, and is a Board member for Enphase Energy. Her expertise in sales, marketing, strategy, and customer operations has allowed her to guide Envisage towards long term viability.`,
          ],
          img: 'jamie_med.jpg',
        },
        {
          name: 'Josh Hibschman, PhD',
          position: 'Product Design Advisor',
          body: [
            `Dr. Hibschman has served as a Founding Engineer at Bloxroute Labs, a Senior Product Engineer at PointDrive (acquired by LinkedIn), a Doctoral Researcher for Google, and a researcher at Northwestern Segal Design Institute.`,
            `In guiding our design process from his abundant experience in the world of design and building human-centered software, he has facilitated a higher-tier quality of design seen in no other college planner.`,
          ],
          img: 'hibschman_med.jpg',
        },
      ],
    }),
    methods: {},
  };
</script>
