<template>
  <v-app>
    <!-- <v-alert class="mt-12" style="z-index: 1" type="success">Alert</v-alert> -->
    <v-system-bar
      :style="`height:${getHeight}px; opacity:${siteNotif ? 1 : 0}`"
      style="z-index: 1000; transition: all .7s; position: fixed; width: 105vw"
      dark
      window
      class="pl-6"
      color="green"
    >
      <v-row>
        <span style="color:white;" class="subtitle-2 mr-3">{{
          siteNotif
        }}</span>
        <span v-for="(action, i) in siteNotificationActions" :key="i">
          <v-btn
            v-if="action.close"
            small
            plain
            style="color:white;"
            @click="closeNotif"
          >
            {{ action.text }}
          </v-btn>
          <v-btn
            v-else
            small
            plain
            style="color:white;"
            target="_blank"
            :href="action.link"
          >
            {{ action.text }}
          </v-btn>
        </span>
      </v-row>
      <v-spacer></v-spacer>
    </v-system-bar>
    <br />
    <navigation
      :site-notif-height="getHeight"
      :color="getColor"
      :flat="getFlat"
    />
    <v-main class="pt-0" :style="`margin-top:${getMargin}px`">
      <router-view></router-view>
      <!-- <home />
      <about />
      <download />
      <pricing /> -->
      <!-- <contact /> -->
    </v-main>
    <v-scale-transition>
      <v-btn
        style="z-index: 100"
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <foote />
  </v-app>
</template>

<style scoped>
.v-main {
  background-image: url("~@/assets/img/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>

<script>
import navigation from "./components/Navigation";
import foote from "./components/Footer";

export default {
  name: "App",
  title: "Envisage Planner",

  components: {
    navigation,
    foote,
  },

  data: () => ({
    fab: null,
    color: "",
    windowWidth: window.innerWidth,
    flat: null,
    siteNotif: "We've released the Summer Update!",
    siteNotificationActions: [
      {
        text: "View Changelog",
        link:
          "https://envisageplanner.notion.site/Envisage-Changelog-c5a5b6a005f646c0bb29a97af2679873",
      },
      {
        text: "Dismiss",
        color: "primary",
        close: true,
      },
    ],
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth < 550;
    },
    getHeight() {
      return this.siteNotif ? (this.isMobile ? 64 : 32) : 0;
    },
    getMargin() {
      if (this.$route.name !== "landing-page") {
        return 64 - (this.siteNotif ? 0 : 32);
      } else return 0 - (this.siteNotif ? 0 : 32);
    },
    getColor() {
      if (this.$route.name !== "landing-page") {
        return "secondary";
      } else return this.color;
    },
    getFlat() {
      if (this.$route.name !== "landing-page") {
        return false;
      } else return this.flat;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  watch: {
    fab(value) {
      console.log(this.$route.name);
      if (value || this.$route.name !== "landing-page") {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    closeNotif() {
      this.siteNotif = "";
      console.log("close");
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
