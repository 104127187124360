<template>
  <v-dialog persistent value="true" max-width="400px"
    ><v-card>
      <v-app-bar class="mb-2" flat dark color="error"> <v-app-bar-title>404 Not Found </v-app-bar-title></v-app-bar>
      <v-card-text
        >Uh oh! It looks like the page you went to does not exist. Were you trying to go to the
        <a href="https://app.envisageplanner.com">Envisage App</a>?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <router-link to="/">
          <v-btn color="primary">Go Home</v-btn>
        </router-link>
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
