<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      dark
      src="@/assets/img/bgDrawer.jpg"
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/img/icon_white_2.png" alt="Logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title"
              >Envisage Planner</v-list-item-title
            >
            <v-list-item-subtitle>Menu</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <router-link
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          :to="link"
          style="text-decoration:none;"
        >
          <v-list-item>
            <v-list-item-icon class="justify-center">
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="subtitile-1">{{
                text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      id="site-nav-bar"
      class="px-15"
      :style="`margin-top:${siteNotifHeight}px`"
      :class="{ expand: flat }"
    >
      <v-toolbar-title>
        <v-img
          src="@/assets/img/logo_white.svg"
          contain
          max-width="200px"
          max-height="50px"
        />
      </v-toolbar-title>
      <!-- <div id="right-align" style="margin-left: auto;"> -->
      <v-spacer v-if="!isMobile" />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isMobile"
      />
      <div v-else>
        <router-link
          v-for="([icon, text, link], i) in items"
          :key="i"
          :to="link"
          style="text-decoration:none;"
        >
          <v-btn text @click="$vuetify.goTo('#hero')" class="mr-2">
            <span>{{ text }}</span>
          </v-btn>
        </router-link>
        <v-btn
          light
          href="https://app.envisageplanner.com"
          class="ml-4"
          rounded
          id="open-app-nav"
        >
          <span>Open Web App</span>
        </v-btn>
        <!-- <v-btn text @click="$vuetify.goTo('#features')">
          <span class="mr-2">About</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#download')">
          <span class="mr-2">Team</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#pricing')">
          <span class="mr-2">Help</span>
        </v-btn> -->
        <!-- <v-btn rounded outlined text @click="$vuetify.goTo('#contact')">
          <span class="mr-2">Go to App</span>
        </v-btn> -->
      </div>
      <!-- </div> -->
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>

<script>
export default {
  data: () => ({
    isMobile: false,
    drawer: null,
    isXs: false,
    items: [
      ["mdi-home-outline", "Home", "/"],
      ["mdi-account-group", "Team", "/team"],
      ["mdi-briefcase-outline", "Work", "/work"],
      ["mdi-handshake", "Invest", "/investor"],
      ["mdi-form-select", "Join", "/join"],
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
    siteNotifHeight: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
      var w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.isMobile = w < 1000;
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
<style scoped>
#open-app-nav {
  animation: scaleIn 0.3s 0.6s backwards;
  -webkit-animation: scaleIn 0.3s 0.6s backwards;
  -moz-animation: scaleIn 0.3s 0.6s backwards;
  -o-animation: scaleIn 0.3s 0.6s backwards;
  -ms-animation: scaleIn 0.3s 0.6s backwards;
}
@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
