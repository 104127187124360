<template>
  <section id="hero">
    <v-parallax
      dark
      src="@/assets/img/hero_back.jpg"
      placeholder="@/assets/img/hero_back_placeholder.jpg"
      height="800"
      style="height: 100vh; min-height: 500px;"
      id="hero-parallax"
    >
      <div id="">
        <video
          playsinline
          autoplay
          muted
          id="hero-video"
          loop
          poster="@/assets/video/landing-loop-poster.png"
          class="vid"
          v-if="!isMobile"
        >
          <source src="@/assets/video/landing-loop.mp4" type="video/mp4" />
          Your browser cannot show video
        </video>
      </div>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <!-- Countdown over! -->
            <v-col
              cols="12"
              md="6"
              xl="8"
              id="hero-text"
              class="pa-5"
              style="background:rgba(0,0,0,.3); border-radius: 10px; "
            >
              <h1 class="display-2 font-weight-bold mb-4">Envisage Planner</h1>
              <h2 class="font-weight-light mb-4">
                Reimagining college through academic planning
              </h2>
              <h3 class="font-weight-light">
                <div class="font-weight-light subtitle-1 ml-0">
                  We've launched our beta!
                </div>
              </h3>
              <v-btn
                rounded
                large
                href="https://app.envisageplanner.com"
                class="mt-5"
              >
                <div id="button-text">Open Web App</div>
                <v-icon class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
              xl="4"
              class="hidden-sm-and-down"
              id="hero-image"
            >
              <!-- <v-img
                style="position: relative; left: 100px; top: 30px"
                width="700"
                src="@/assets/img/envisage_laptop.png"
              /> -->
            </v-col></v-row
          >
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <div class="wave" />
        <div class="wave" />
        <div class="wave" />

        <!-- <v-img src="@/assets/img/borderWaves.svg" /> -->
        <!-- <v-img src="@/assets/img/borderWaves.svg" /> -->
      </div>
    </v-parallax>

    <div style="background: white; margin-top: -8px;">
      <div
        style="width: 100%; height: 100%; position: absolute; background: white;"
      />
      <v-container fluid id="features" class="mt-2">
        <v-row align="center" justify="center">
          <v-col cols="10" style="z-index:5;">
            <v-row align="center" justify="space-around">
              <!-- <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">Title</h1>
              <h1 class="font-weight-light">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </h1>
            </v-col> -->
              <v-col
                cols="12"
                sm="4"
                class="text-center "
                v-for="(feature, i) in features"
                :key="i"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    class="card"
                    shaped
                    :elevation="hover ? 10 : 4"
                    :class="{ up: hover }"
                  >
                    <v-img
                      :src="feature.img"
                      :id="feature.identifier"
                      v-if="feature.identifier === undefined"
                      max-width="100px"
                      class="d-block ml-auto mr-auto"
                      :class="{ 'zoom-efect': hover }"
                    ></v-img>
                    <svgIcons
                      v-else
                      max-width="100px"
                      style="transition: all .75s"
                      class="d-block ml-auto mr-auto"
                      :class="{ 'zoom-efect': hover }"
                      :id="feature.identifier"
                    />
                    <h1 class="font-weight-regular">{{ feature.title }}</h1>
                    <h4 class="font-weight-regular subtitle-1 pa-2 text-xl-h6">
                      {{ feature.text }}
                    </h4>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-dialog v-model="dialog" max-width="640px">
      <v-card>
        <youtube
          :video-id="videoId"
          @ready="ready"
          @playing="playing"
        ></youtube>
      </v-card>
    </v-dialog>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
  </section>
</template>

<script>
import SvgIcons from "./SvgIcons.vue";

const axios = require("axios");
axios.defaults.baseURL = "https://api.envisageplanner.com";
export default {
  components: {
    SvgIcons,
  },
  data() {
    return {
      email: "",
      emailLoading: false,
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      emailMessages: "",
      emailErrorMessages: "",
      dialog: false,
      videoId: "i8IvvHJssWE",
      features: [
        {
          identifier: 0,
          title: "Planning, Consolidated",
          text: `Envisage Planner covers all of your planning needs in one, whether that's choosing a major, planning out your semesters, or learning about a course.`,
        },
        {
          identifier: 1,
          title: "Stellar User Experience",
          text: `We know the pain of planning on poorly designed tools. That's why we have built Envisage to facilitate planning at the speed of thought.`,
        },
        {
          identifier: 2,
          title: "For Students & Faculty",
          text: `You shouldn't have to choose between a tool that's good for students and one that's good for faculty. We want to make a tool for both.`,
        },
      ],
    };
  },
  mounted() {},
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  computed: {
    isMobile() {
      var w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      return w < 800;
    },
  },
  methods: {
    input() {
      this.emailMessages = "";
      this.emailErrorMessages = "";
    },
    saveEmail() {
      this.$refs.email.blur();
      console.log("Send email");
      if (this.email.length > 4 && this.emailRules[0](this.email) === true) {
        this.emailLoading = true;
        axios
          .post("/email", {
            email: this.email,
          })
          .then((res) => {
            this.emailLoading = false;
            this.emailMessages = "Great! We'll send you an email on the 18th";
          })
          .catch((err) => {
            this.emailErrorMessages = "Sorry, something went wrong";
          });
      }
    },
    ready(event) {
      this.player = event.target;
    },
    playing(event) {
      // The player is playing a video.
    },
    change() {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style lang="scss">
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
.svg-border-waves {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}
/* Wave code repurposed from this codepen: https://codepen.io/tedmcdo/pen/PqxKXg */

.wave {
  background: url(~@/assets/img/animations/wave-2.svg) repeat-x;
  position: absolute;
  top: -68px;
  width: 6400px;
  height: 198px;
  animation: wave 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0) scaleY(0.3);
}
.blue-waves {
  background: url(~@/assets/img/animations/wave-3.svg) repeat-x !important;
}

.wave:nth-of-type(2) {
  top: -75px;
  animation: wave 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 0.75;
}

.wave:nth-of-type(3) {
  top: -70px;
  animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) -2.525s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
  z-index: -1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, 25px, 0) scaleY(0.5);
  }
  50% {
    transform: translate3d(0, 5px, 0) scaleY(0.5);
  }
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

#hero-text {
  animation: fadeIn 0.5s 0.2s backwards;
  h1 {
    animation: fadeIn 0.5s 0.4s backwards;
  }
  h2 {
    animation: fadeIn 0.5s 0.6s backwards;
  }
  h3 {
    animation: fadeIn 0.5s 0.8s backwards;
  }
  a {
    animation: buttonIn 1s 1s both ease-in-out;
  }
  i {
    animation: buttonArrowIn 1s 1s both ease-in-out;
  }
  #button-text {
    overflow: hidden;
    animation: buttonTextIn 1s 1s both ease-in-out;
  }
}
// #hero-image {
//   animation: fadeIn 1s 0.4s backwards;
//   -webkit-animation: fadeIn 1s 0.4s backwards;
//   -moz-animation: fadeIn 1s 0.4s backwards;
//   -o-animation: fadeIn 1s 0.4s backwards;
//   -ms-animation: fadeIn 1s 0.4s backwards;
// }
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes buttonIn {
  0% {
    opacity: 0;
    width: 15px;
  }
  35% {
    opacity: 1;
    width: 15px;
  }
  90% {
    width: 183.95px;
  }
}
@keyframes buttonTextIn {
  0% {
    width: 0px;
  }
  50% {
    width: 0px;
  }
  100% {
    width: 115px;
  }
}
@keyframes buttonArrowIn {
  0% {
    opacity: 0;
    right: 5px;
  }
  40% {
    opacity: 1;
    right: 5px;
  }
  60% {
    right: -10px;
  }
  90% {
    right: -10px;
  }
  100% {
    right: 0px;
  }
}
</style>

<style lang="scss">
#consolidation-icon {
  border: black;
}
section {
  position: relative;
}
.vid,
#vid-wrapper {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: -1;
  right: 0;
}
#hero-parallax .v-parallax__image-container {
  background: black;
}
// #hero-parallax .v-parallax__image-container img {
//   animation: fadeee 1s 2s forwards;
// }
// @keyframes fadeee {
//   from {
//     opacity: 0 !important;
//   }
//   to {
//     opacity: 1 !important;
//   }
// }

.vid {
  filter: blur(3px);
}
#vid-wrapper {
  position: absolute;
  background: black;
}

.svg-border-waves img {
  z-index: 2;
}
.hideBG .v-parallax__image-container {
  opacity: 0;
}
</style>
