import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import titleMixin from "./mixins/titleMixin";
import router from "./router";

Vue.mixin(titleMixin);
Vue.use(VueRouter);

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import "firebase/performance";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmBsUT3X-tL76egIxQVnL3yjh8A5RRbto",
  authDomain: "envisage-planner.firebaseapp.com",
  databaseURL: "https://envisage-planner-default-rtdb.firebaseio.com",
  projectId: "envisage-planner",
  storageBucket: "envisage-planner.appspot.com",
  messagingSenderId: "579398198929",
  appId: "1:579398198929:web:326cc6599697093ee64130",
  measurementId: "G-6RBNMYYS1V",
};
firebase.initializeApp(firebaseConfig);

Vue.prototype.$analytics = firebase.analytics();
Vue.prototype.$performance = firebase.performance();

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
