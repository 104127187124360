<template>
  <section class="pb-8 pt-8" id="contact">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col sm="7" xl="4" class="hidden-xs-only">
              <!-- <v-img
                src="@/assets/img/shark_tank_winner.png"
                class="d-block ml-auto mr-auto"
                height="200"
                contain
              /> -->
              <svgIcons
                height="200px"
                class="d-block ml-auto mr-auto"
                :id="9"
              />
            </v-col>
            <v-col cols="12" justify="center" sm="5" xl="4">
              <h1 class="font-weight-light text-h6 text-md-h4  text-center">
                Taylor University Shark Tank
              </h1>
              <h3 class=" font-weight-regular text-lg-h6 mt-3">
                You may remember us as the team that won Taylor University's
                Shark Tank! Watch the winning presentation
                <a
                  target="_blank"
                  href="https://www.youtube.com/watch?v=Wvy76NQIs4Y"
                  >here!</a
                >
              </h3>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg" />
    </div> -->
    <v-snackbar
      v-model="snackbar.enabled"
      timeout="3000"
      right
      top
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.enabled = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
</style>

<script>
// import {db} from '@/main'
import SvgIcons from "./SvgIcons.vue";
export default {
  components: {
    SvgIcons,
  },
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "O campo nome é obrigatório",
      (v) => (v && v.length >= 6) || "O nome precisa ter mais de 6 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "O campo email é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "O E-mail precisa ser válido",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "O campo de texto é obrigatório",
      (v) => (v && v.length >= 10) || "Mínimo de 10 caracteres",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: "",
      color: "",
    },
  }),
  methods: {
    submit() {
      /*db.collection("contactData").add({
        name: this.name,
        email: this.email,
        message: this.textArea
      }).then(() => {
        this.snackbar.text = "Mensagem enviada com sucesso"
        this.snackbar.color = "success"
        this.snackbar.enabled = true
      }).catch(() => {
        this.snackbar.text = "Erro ao enviar mensagem"
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      })*/
    },
  },
};
</script>
