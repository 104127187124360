<template>
  <div style="overflow: hidden;">
    <home />
    <contact />
    <about />
    <download />
    <sharkTank />
    <pricing />
  </div>
</template>

<script>
import home from "../components/HomeSection";
import about from "../components/AboutSection";
import download from "../components/DownloadSection";
import pricing from "../components/PricingSection";
import contact from "../components/ContactSection";
import sharkTank from "../components/SharkTank";

export default {
  components: {
    home,
    about,
    download,
    pricing,
    contact,
    sharkTank,
  },
};
</script>

<style lang="scss" scoped></style>
