<template>
  <section id="help" class="pb-8" style="background: white;">
    <v-container fluid class="mb-5">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-card style="width: 100%; z-index: 1;">
            <h1
              class="text-center pt-6 font-weight-light  text-h5 text-md-h4  "
            >
              Help Us
            </h1>
            <v-divider class="my-6"></v-divider>
            <v-row class="text-center">
              <v-col class="col-12 col-md-4">
                <v-divider
                  v-if="this.$vuetify.breakpoint.smOnly"
                  class="mx-4"
                ></v-divider>

                <div class="flex-center">
                  <v-card-text>
                    <div class="flex-center">
                      <svgIcons max-width="150px" :id="10" />
                      <!-- <div class="circle1">
                        <div class="circle2">
                          <v-img src="~@/assets/img/icons/paperplane.svg" />
                        </div>
                      </div> -->
                    </div>
                    <div class="text--disabled text-uppercase text-h5 my-2">
                      Share
                    </div>
                    <div
                      class="font-weight-regular text-lg-h6 subtitle-2 font-weight-light mt-6"
                    >
                      A great tool doesn't matter if nobody knows about it. You
                      are our most powerful ambassador!
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Share this site with friends
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Follow/Share our Instagram
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Tell your advisor and professors
                    </div>
                    <v-divider class="my-2" />
                    <v-fade-transition>
                      <v-btn
                        v-bind="size"
                        @click="copyUrl"
                        rounded
                        outlined
                        :color="copyButtonNotif ? 'green' : 'blue'"
                        class="mt-6 font-weight-regular text-lg-h6"
                      >
                        {{
                          copyButtonNotif ? "Link Copied!" : "Copy Site Link "
                        }}
                      </v-btn>
                    </v-fade-transition>
                  </v-card-text>
                  <v-divider
                    style="margin-right: -23px"
                    vertical
                    v-if="this.$vuetify.breakpoint.mdAndUp"
                  ></v-divider>
                </div>
              </v-col>
              <v-col class="col-12 col-sm-6 col-md-4">
                <div class="flex-center">
                  <v-card-text>
                    <div class="flex-center">
                      <svgIcons max-width="150px" :id="11" />
                      <!-- <div class="circle1">
                        <div class="circle2">
                          <v-img src="~@/assets/img/icons/partner.svg"></v-img>
                        </div>
                      </div> -->
                    </div>
                    <div class="text--disabled text-uppercase text-h5 my-2">
                      Work
                    </div>
                    <div
                      class="font-weight-regular text-lg-h6 subtitle-2 font-weight-light mt-6"
                    >
                      There are many ways to help us with your talents, whether
                      that be paid, volunteer, or for class credit
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Software Engineer
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      UI/UX Design
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Graphics, Illustration
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Film, Marketing, Social Media
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Ambassador, Leader, Business
                    </div>
                    <v-divider class="my-2" />
                    <router-link
                      style="text-decoration: none !important;"
                      to="/work"
                    >
                      <v-btn
                        v-bind="size"
                        rounded
                        outlined
                        color="blue"
                        class="mt-6 font-weight-regular text-lg-h6"
                      >
                        Learn More
                      </v-btn>
                    </router-link>
                  </v-card-text>
                  <v-divider
                    style="margin-right: -23px"
                    vertical
                    v-if="this.$vuetify.breakpoint.smAndUp"
                  ></v-divider>
                </div>
                <v-divider
                  class="mx-4"
                  v-if="!this.$vuetify.breakpoint.smAndUp"
                ></v-divider>
              </v-col>
              <v-col class="col-12 col-sm-6 col-md-4">
                <div class="flex-center">
                  <v-card-text>
                    <div class="flex-center">
                      <svgIcons max-width="150px" :id="12" />

                      <!-- <div class="circle1">
                        <div class="circle2">
                          <v-img src="~@/assets/img/icons/invest.svg" />
                        </div>
                      </div> -->
                    </div>

                    <div class="text--disabled text-uppercase text-h5 my-2">
                      Invest
                    </div>
                    <div
                      class="font-weight-regular text-lg-h6 subtitle-2 font-weight-light mt-6"
                    >
                      In order for Envisage to succeed, we need support from
                      those who share our passion to serve students and
                      re-invent academic planning.
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Financial Investment
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Advisor
                    </div>
                    <v-divider class="my-2" />
                    <div
                      class="font-weight-regular text-xl-h6 text-uppercase blue--text"
                    >
                      Supporting Us at your University
                    </div>
                    <v-divider class="my-2" />
                    <router-link
                      style="text-decoration: none !important;"
                      to="/investor"
                    >
                      <v-btn
                        v-bind="size"
                        rounded
                        outlined
                        color="blue"
                        class="mt-6 font-weight-regular text-lg-h6"
                      >
                        Learn More
                      </v-btn>
                    </router-link>
                  </v-card-text>
                </div>
                <v-divider
                  class="mx-4"
                  v-if="!this.$vuetify.breakpoint.smAndUp"
                ></v-divider>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div
      class="svg-border-waves text-white"
      style="transform: translateY(20px)"
    >
      <div class="wave blue-waves" />
      <div class="wave blue-waves" />
      <div class="wave blue-waves" />

      <!-- <v-img src="@/assets/img/borderWaves.svg" /> -->
      <!-- <v-img src="@/assets/img/borderWaves.svg" /> -->
    </div>
    <!-- <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg" />
    </div> -->
    <div class="svg-border-rounded text-light">
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 144.54 17.34"
        preserveAspectRatio="none"
        fill="currentColor"
      >
        <path
          d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"
        />
      </svg> -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        fill="currentColor"
      >
        <path
          d="M0,64L80,90.7C160,117,320,171,480,181.3C640,192,800,160,960,138.7C1120,117,1280,107,1360,101.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </div>
  </section>
</template>

<style lang="scss">
$main_color: #283e79;

ul {
  font-size: 13px;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;

  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }

  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: $main_color;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-eeborder-radius: 50%;
    }
  }
}
</style>

<style scoped>
.header {
  background-color: #283e79;
  color: white;
}

.circle1 {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: #f0f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle2 {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #e0effc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #f4f7f5;
  z-index: -1;
}

#pricing {
  z-index: 0;
}

.content {
  z-index: 1;
}

svg {
  overflow: hidden;
}

section {
  position: relative;
}
</style>

<script>
import SvgIcons from "./SvgIcons.vue";
export default {
  components: {
    SvgIcons,
  },
  data: () => ({
    copyButtonNotif: false,
    planos: [
      {
        title: "Básico",
        price: "R$100,00",
        img: "f1.png",
        features: [
          {
            icon: {
              text: "mdi-check",
              color: "success",
            },
            text: "Feature 1",
          },
          {
            icon: {
              text: "mdi-check",
              color: "success",
            },
            text: "Feature 2",
          },
          {
            icon: {
              text: "mdi-cancel",
              color: "red",
            },
            text: "Feature 3",
          },
          {
            icon: {
              text: "mdi-cancel",
              color: "red",
            },
            text: "Feature 4",
          },
        ],
      },
      {
        title: "Padrão",
        price: "R$150,00",
        img: "f2.png",
        features: [
          {
            icon: {
              text: "mdi-check",
              color: "success",
            },
            text: "Feature 1",
            color: "success",
          },
          {
            icon: {
              text: "mdi-check",
              color: "success",
            },
            text: "Feature 2",
          },
          {
            icon: {
              text: "mdi-check",
              color: "success",
            },
            text: "Feature 3",
            color: "success",
          },
          {
            icon: {
              text: "mdi-cancel",
              color: "red",
            },
            text: "Feature 4",
          },
        ],
      },
      {
        title: "Premium",
        price: "R$250,00",
        img: "f3.png",
        features: [
          {
            icon: {
              text: "mdi-check",
              color: "success",
            },
            text: "Feature 1",
            color: "success",
          },
          {
            icon: {
              text: "mdi-check",
              color: "success",
            },
            text: "Feature 2",
          },
          {
            icon: {
              text: "mdi-check",
              color: "success",
            },
            text: "Feature 3",
            color: "success",
          },
          {
            icon: {
              text: "mdi-check",
              color: "success",
            },
            text: "Feature 4",
            color: "success",
          },
        ],
      },
    ],
  }),
  computed: {
    size() {
      const size = { md: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
  methods: {
    copyUrl() {
      var dummy = document.createElement("input"),
        text = "https://envisageplanner.com";

      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.copyButtonNotif = true;

      setTimeout(
        function(obj) {
          obj.copyButtonNotif = false;
        },
        2000,
        this
      );
    },
  },
};
</script>
