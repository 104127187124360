<template>
  <section id="byStudents">
    <v-container fluid style="height:100%">
      <v-row align="center" class="mt-6" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col sm="4" class="hidden-xs-only">
              <svgIcons
                class="d-block ml-auto mr-auto"
                max-width="500px"
                style="width: 100%"
                :id="8"
              />

              <!-- <v-img
                src="@/assets/img/byStudents.png"
                class="d-block ml-auto mr-auto"
                max-width="500px"
              /> -->
            </v-col>
            <v-col cols="12" sm="8" class="white--text text-left">
              <h1 class="font-weight-light text-md-h4 mb-2">
                By Students, For Students
              </h1>
              <h1
                class="font-weight-light text-subtitle-1 font-weight-regular text-lg-h6"
              >
                Whether it's our code, design, marketing, or leadership,
                Envisage Planner is 100% student driven and lead. Over the past
                two years we have written over 35,000 lines of code, all
                informed by personal experience, rigorous user testing, and
                consulting with university faculty.
              </h1>
              <router-link to="team" style="text-decoration:none">
                <v-btn rounded outlined large color="white" class="mt-4">
                  <!-- <v-icon class="mr-2">
                  mdi-github
                </v-icon> -->
                  Meet the Team
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style scoped>
#byStudents {
  background-image: url("~@/assets/img/code.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
}

#download .container,
#download .row {
  height: 100%;
}
</style>

<script>
import SvgIcons from "./SvgIcons.vue";
export default {
  components: {
    SvgIcons,
  },
};
</script>
